import React from "react";
import Layout from "../../components/Layout";

// eslint-disable-next-line
export default () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content">
          <h1>Aviso Legal</h1>
          <div>EaiTalento es una marca de Eai Consultor&iacute;a S.L, CIF B64845027, Con sede Fiscal en&nbsp;Paseo de las Delicias, 30 2, 28045 Madrid.&nbsp;&nbsp;</div>
          <div>Tel.&nbsp;<span class="highlight"><span class="colour"><span class="font"><span class="size">902886191.</span></span></span></span></div>
          <div>&nbsp;</div>
          <div><strong><u>Aviso de privacidad para la selecci&oacute;n de personal</u></strong></div>
          <ul>
            <li>EaiTalento se compromete en proteger y respetar su privacidad. La presente directiva es la base sobre la cual procesaremos los datos personales que recopilemos de los candidatos a empleos, o los datos que nos proporcionen personas o fuentes externas con fines de selecci&oacute;n de personal.</li>
            <li>Cada vez que presente una solicitud para una oferta de empleo publicada por nosotros, son aplicables las disposiciones de este Aviso de Privacidad con respecto a la forma en que procesamos su informaci&oacute;n personal. Esto se suma a nuestro otro Aviso de Privacidad, que se le ha proporcionado por separado o que est&aacute; disponible en nuestro sitio web.</li>
            <li>Los <strong>datos personales</strong> son toda la informaci&oacute;n que est&aacute; relacionada con un individuo identificado o individuo identificable.</li>
            <li>A los efectos del Reglamento General de Protecci&oacute;n de Datos (&ldquo;RGPD&rdquo;), el responsable de los datos es EaiTalento.</li>
            <li>En nuestro proceso de selecci&oacute;n de personal utilizamos la aplicaci&oacute;n Zoho Recruit basada en la nube. Por lo tanto, Zoho Recruit act&uacute;a como encargado de los datos en nuestro nombre y solo tiene derecho a procesar sus datos personales de acuerdo con nuestras instrucciones.</li>
            <li>Esta directiva creada por nosotros no se aplica a Zoho Recruit.</li>
            <li>Esta directiva tambi&eacute;n proporciona al usuario determinada informaci&oacute;n que se debe proporcionar en virtud del Reglamento general de protecci&oacute;n de datos (<em>(UE) 2016/679</em>) (RGPD) y la ley local de protecci&oacute;n de datos.</li>
            <li>Esta directiva no forma parte de ning&uacute;n acuerdo contractual y puede ser modificada en cualquier momento.</li>
          </ul>
          <p><strong><u>&iquest;C&oacute;mo se recopilan sus datos personales?</u></strong></p>
          <div>Recopilamos datos personales sobre usted de las fuentes siguientes:</div>
          <ul>
            <li>A trav&eacute;s de usted, el candidato, a trav&eacute;s de un sitio web de empleo, correo electr&oacute;nico, en persona en entrevistas de v&iacute;deo o por cualquier otro medio.</li>
            <li>Aquellos que recomendaron al candidato, de los cuales obtenemos los datos personales del candidato.</li>
            <li>Proveedores externos de confianza que le recomendaron a usted como candidato para una oferta de empleo determinada o para nuestro negocio en general.</li>
            <li>A partir de fuentes de acceso p&uacute;blico, tales como LinkedIn o Source Boosters (Indeed, Monster, Career Builder, Dice, etc.), de donde obtenemos su nombre completo, correo electr&oacute;nico, historial laboral y otros datos incluidos en su perfil. Encontrar&aacute; informaci&oacute;n detallada sobre este tema en la secci&oacute;n mostrada m&aacute;s abajo.</li>
          </ul>
          <p><strong><u>Informaci&oacute;n personal que recopilamos de usted</u></strong></p>
        </div>
        <ul>
          <li>En particular, recopilamos los datos personales siguientes:</li>
          <ul>
            <li>Nombre</li>
            <li>Informaci&oacute;n de contacto</li>
            <li>Formaci&oacute;n acad&eacute;mica</li>
            <li>Historial laboral</li>
            <li>Calificaciones laborales/conocimientos</li>
            <li>Curr&iacute;culo o transcripciones, y cualquier documentaci&oacute;n justificativa adjunta a su curr&iacute;culo, tales como copias de certificados, pasaporte, cartas de presentaci&oacute;n, etc.</li>
          </ul>
          <li>Detalles de sus visitas a nuestro sitio web de empleo, incluidos, entre otros, datos de tr&aacute;fico, datos de ubicaci&oacute;n, registros web y otros datos de comunicaci&oacute;n, el sitio web que lo remiti&oacute; a nuestro sitio web y los recursos a los que usted accede.</li>
        </ul>
        <p><strong><u>Base legal para el procesamiento de datos</u></strong></p>
        <p>Nos amparamos en el inter&eacute;s leg&iacute;timo como base legal por la cual recopilamos y usamos sus datos personales.</p>
        <p>Dondequiera que un candidato presente una solicitud para una vacante a trav&eacute;s de &ldquo;Presentaci&oacute;n r&aacute;pida de candidatura&rdquo; y de nuestras bolsas de trabajo integradas, nos amparamos en el consentimiento durante el proceso de candidaturas para transmitir los datos personales del candidato al proveedor de servicios de acuerdo con los principios siguientes.</p>
        <p><strong><u>Finalidades del procesamiento de datos</u></strong></p>
        <p>Procesamos datos personales relacionados con usted con el fin de poder evaluar su idoneidad para un empleo. Sin dicho procesamiento, no ser&iacute;a posible para nosotros evaluar su solicitud de empleo. Utilizamos la informaci&oacute;n que tenemos sobre usted para cualquiera o todos los fines siguientes:</p>
        <ul>
          <li>Identificar y evaluar candidatos para posibles empleos, as&iacute; como para futuros puestos de trabajo que puedan estar disponibles</li>
          <li>Mantener registros en relaci&oacute;n con la selecci&oacute;n y contrataci&oacute;n de personal</li>
          <li>Comunicarnos con usted y responder a sus solicitudes o candidatura</li>
        </ul>
        <p><u><strong>Divulgaci&oacute;n de informaci&oacute;n personal</strong></u></p>
        <div>Tenemos cuidado de compartir su informaci&oacute;n personal solo con quienes realmente la necesitan para realizar sus tareas y funciones.</div>
        <div>Principalmente, transmitimos su informaci&oacute;n a nuestros proveedores de servicios, incluidos Zoho Recruit, sus subencargados de datos y los servicios de terceros habilitados por nosotros en Zoho Recruit, quienes usan su informaci&oacute;n solo de acuerdo con nuestras instrucciones y seg&uacute;n lo exigido por la ley.</div>
        <div>
          <p><strong>Ejemplo:</strong></p>
        </div>
        <ol>
          <li>Zoho Recruit puede contratar a un subencargado de datos para que nos ayude a analizar el curr&iacute;culo del candidato y obtener la informaci&oacute;n necesaria para el proceso de candidaturas y la entrevista.</li>
          <li>Podemos transmitir su informaci&oacute;n personal a servicios de terceros proporcionados por Zoho Recruit para fines tales como comprobaci&oacute;n de antecedentes, evaluaci&oacute;n, etc.</li>
          <li>Siempre que usted haya solicitado un puesto vacante a trav&eacute;s de nuestra funci&oacute;n Smart Apply, y dondequiera que haya dado su consentimiento para esta divulgaci&oacute;n, divulgaremos al proveedor de servicios ciertos datos personales que tenemos sobre usted, incluidos, entre otros, un identificador &uacute;nico utilizado por el proveedor de servicios para identificarle e informaci&oacute;n sobre su progreso en el proceso de contrataci&oacute;n para la vacante correspondiente; as&iacute; como informaci&oacute;n tangible, intangible, visual, electr&oacute;nica, presente o futura que tengamos sobre el usted, tal como su nombre, informaci&oacute;n de contacto y otros datos que intervienen en el proceso de an&aacute;lisis de datos relacionado con usted como solicitante de empleo (datos conocidos colectivamente como &ldquo;Datos de disposici&oacute;n&rdquo;). El Aviso de privacidad del proveedor de servicios relacionado con el uso de estos datos compartidos estar&aacute; disponible en el sitio web del proveedor de servicios. Para conocer m&aacute;s detalles, obtenga m&aacute;s informaci&oacute;n sobre Smart Apply.</li>
        </ol>
        <p><strong><u>Seguridad</u></strong></p>
        <div>Hemos aplicado medidas de seguridad adecuadas para evitar que sus datos personales se pierdan, utilicen, accedan de forma no autorizada, modifiquen o divulguen de forma accidental. Adem&aacute;s, hemos limitado el acceso a sus datos personales a aquellos empleados, agentes, contratistas y otras terceras personas que tengan una necesidad comercial de conocer esos datos. Dichos agentes solo tratar&aacute;n sus datos personales siguiendo nuestras instrucciones espec&iacute;ficas, y est&aacute;n sujetos a un deber de confidencialidad.</div>
        <p>Si se produce cualquier vulneraci&oacute;n de seguridad, le notificaremos a usted y a cualquier organismo regulador pertinente cuando estemos legalmente obligados a hacerlo.</p>
        <p><strong><u>D&oacute;nde almacenamos sus datos personales y qui&eacute;n tiene acceso a ellos</u></strong></p>
        <div>Almacenamos sus datos personales en Europe.</div>
        <div>Si usted es un candidato que reside en el Espacio Econ&oacute;mico Europeo:</div>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Los datos que recopilamos de usted y procesamos se pueden transferir a un destino fuera del Espacio Econ&oacute;mico Europeo (&ldquo;EEE&rdquo;). Esos datos tambi&eacute;n pueden ser accedidos y procesados por personal que opera fuera del EEE y trabaja para nosotros o para uno de nuestros proveedores.</p>
        <div>Si desea obtener m&aacute;s informaci&oacute;n, comun&iacute;quese con nosotros (consulte &ldquo;Contactar&rdquo; m&aacute;s abajo).</div>
        <p><strong><u>Cu&aacute;nto tiempo almacenamos sus datos personales</u></strong></p>
        <p>Determinaremos los per&iacute;odos de retenci&oacute;n de datos apropiados teniendo en cuenta los fines establecidos en esta directiva y los requisitos legales pertinentes. Su informaci&oacute;n personal se eliminar&aacute; en uno de los casos siguientes:</p>
        <ul>
          <li>Recepci&oacute;n de una solicitud escrita por usted dirigida a nosotros.</li>
          <li>Eliminaci&oacute;n de informaci&oacute;n personal en virtud de criterios definidos por nosotros.
            <ul>
              <li>Por ejemplo, si no recibimos su consentimiento dentro del plazo definido, sus datos se eliminar&aacute;n autom&aacute;ticamente.</li>
            </ul>
          </li>
        </ul>
        <p><strong><u>Sus derechos</u></strong></p>
        <div>En virtud del <a href="http://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32016R0679&amp;from=EN">Reglamento General de Protecci&oacute;n de Datos</a>, usted tiene varios derechos importantes de forma gratuita. En resumen, esos derechos incluyen:</div>
        <ul>
          <li>Derecho a conocer y acceder</li>
          <li>Derecho a solicitar correcci&oacute;n</li>
          <li>Derecho a solicitar la supresi&oacute;n</li>
          <li>Derecho a recibir una copia de sus datos</li>
          <li>Derecho a oponerse al procesamiento y al marketing directo</li>
          <li>Derecho a oponerse a la toma de decisiones automatizada</li>
          <li>Derecho a restringir el procesamiento</li>
        </ul>
        <p>Si desea ejercer cualquiera de esos derechos, por favor:</p>
        <ul>
          <li>Comun&iacute;quese con nosotros utilizando nuestros datos de contacto mostrados m&aacute;s abajo</li>
          <li>Denos informaci&oacute;n suficiente para confirmar su identidad</li>
          <li>D&iacute;ganos a qu&eacute; informaci&oacute;n hace referencia su solicitud</li>
        </ul>
        <p><strong><u>C&oacute;mo presentar una queja</u></strong></p>
        <div>Esperamos poder resolver cualquier pregunta o inquietud que plantee sobre el uso que hacemos de sus datos.</div>
        <div>El <a href="http://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32016R0679&amp;from=EN"> Reglamento General de Protecci&oacute;n de Datos </a> le concede el derecho a presentar una queja ante un organismo regulador adecuado, especialmente si usted reside en el Espacio Econ&oacute;mico Europeo.</div>
        <p><strong><u>Contactar</u></strong></p>
        Todas las preguntas, comentarios y solicitudes relacionados con este Aviso de privacidad se deben dirigir a <a href="mailto:gestion@eaitalento.com" target="_blank" rel="noopener">gestion@eaitalento.com</a></div>
     </section>
     </Layout >
   


 
);
